<template>
  <v-container
    v-if="ratingData"
    fluid>
    <v-form
      v-model="isFormValid"
      @submit.prevent="save"
    >
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                v-model="ratingData.product_id"
                :rules="[rules.required]"
                :items="rentProducts"
                :loading="rentProductLoading"
                item-text="name"
                item-value="id"
                label="Produkt"
              />
              <v-text-field
                v-model="ratingData.customer_name"
                label="Kundenname"
                :rules="[rules.required]"
                outlined/>
              <v-text-field
                v-model="ratingData.order_id"
                label="Bestellnummer"
                type="number"
                outlined/>
              <v-rating
                v-model="ratingData.rating"
                background-color="primary lighten-3"
                color="primary"
                half-increments
                length="5" />
            </v-col>
            <v-col cols="6">
              <v-textarea
                v-model="ratingData.rating_description"
                label="Beschreibungstext"
                :rules="[rules.required]"
                rows="6"
                outlined/>
            </v-col>
          </v-row>
          <v-row>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :disabled="isButtonDisabled"
            :loading="loading"
            color="success"
            type="submit">
            Speichern
          </v-btn>
          <v-spacer/>
          <ConfirmDeletionModal
            v-if="this.ratingData.id"
            :rating="this.ratingData" />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import RatingsAPI from '@/api/ratings'
import ConfirmDeletionModal from '../../components/rating/components/ConfirmDeletionModal.vue'

export default {
  data() {
    return {
      isFormValid: false,
      ratingData: null,
      loading: false,
      rules: {
        required: (value) => !!value || 'Erforderlich',
      },
    }
  },
  created() {
    this.$store.dispatch('product/fetchRentProducts')
    if (this.$route.params.ratingId) {
      this.$store.dispatch('rating/fetchRating', this.$route.params.ratingId)
    }
  },
  beforeDestroy() {
    this.$store.commit('rating/setRating', null)
  },
  computed: {
    ...mapGetters({
      rating: 'rating/rating',
      rentProducts: 'product/rentProducts',
      rentProductLoading: 'product/loading',
    }),
    isButtonDisabled() {
      return this.loading || !this.isFormValid
    },
  },
  watch: {
    rating: {
      handler() {
        if (this.$route.params.ratingId) {
          this.ratingData = JSON.parse(JSON.stringify(this.rating))
        } else {
          this.ratingData = {
            id: null,
            customer_name: '',
            order_id: null,
            product_id: null,
            rating: 0,
            rating_description: '',
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    save() {
      this.loading = true
      let apiCall = null
      if (this.ratingData.id) {
        apiCall = RatingsAPI.update(this.ratingData)
      } else {
        apiCall = RatingsAPI.create(this.ratingData)
      }
      apiCall.then(() => {
        this.$toasted.success('Die Bewertung wurde gespeichert')
        this.$router.push('/ratings')
      }).catch((errorResponse) => {
        errorResponse.json().then((error) => {
          this.$toasted.error(error.message || 'Die Bewertung konnte leider nicht gespeichert werden')
        })
      }).finally(() => {
        this.loading = false
      })
    },
    deleteRating() {
      this.loading = true
      RatingsAPI.delete(this.ratingData).then(() => {
        this.$toasted.success('Bewertung wurde gelöscht')
        this.$router.push('/ratings')
      }).catch(() => {
        this.$toasted.error('Bewertung konnte nicht gelöscht werden')
      }).finally(() => {
        this.loading = false
      })
    },
  },
  components: {
    ConfirmDeletionModal,
  },
}
</script>
